import useAlerts from 'hooks/useAlerts'
import { isEmptyArray } from 'lib/utils'
import { Fragment } from 'react'

type Props = {
  type: string
}

const Alerts = ({ type }: Props) => {
  const alerts = useAlerts(type)

  if (isEmptyArray(alerts)) return null

  return (
    <div className='h-max text-center text-xs lg:text-base2 py-3 px-4 leading-4 bg-alert'>
      {alerts.map((a) => (
        <Fragment key={a.id}>
          <div
            className='text-accent font-semibold'
            dangerouslySetInnerHTML={{
              __html: a?.title ?? '',
            }}
          ></div>
          <div
            className='text-dark font-normal'
            dangerouslySetInnerHTML={{
              __html: a?.text ?? '',
            }}
          ></div>
        </Fragment>
      ))}
    </div>
  )
}

export default Alerts
