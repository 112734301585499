import CategoryLeftMenu from 'components/categories/CategoryLeftMenu'
import CategorySlider from 'components/categories/CategorySlider'
import Alerts from 'components/common/Alerts'
import LoadingDots from 'components/common/LoadingDots'
import LoadingOverlay from 'components/common/LoadingOverlay'
import ArrowUpIcon from 'components/icons/ArrowUpIcon'
import ProductCard from 'components/product/ProductCard'
import ProductModal from 'components/product/ProductCard/ProductModal'
import useCart from 'hooks/useCart'
import useMenus from 'hooks/useMenus'
import useSlug from 'hooks/useSlug'
import {
  isAbsent,
  isEmptyArray,
  isNonEmptyArray,
  isNonEmptyObj,
  isNonEmptyString,
  isPresent,
} from 'lib/utils'
import dynamic from 'next/dynamic'
import { HomePageProps } from 'pages'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ICategory,
  IProduct,
  selectBannersList,
  selectCategoryList,
  selectProductList,
  useGetPageQuery,
} from 'store/api'
import { selectIsBrowser, selectIsSm } from 'store/ui'
import HomeCategoryMobileMenu from './HomeCategoryMobileMenu'
import Head from 'next/head'

const HomeBannerSlider = dynamic(() => import('./HomeBannerSlider'), {
  loading: () => <LoadingDots />,
})

const HomeView = ({
  seoPage,
  categoryListSSR,
  productListSSR,
  menus: menusSSR,
}: HomePageProps) => {
  const { queryLng } = useSlug()

  let [product, setProduct] = useState<IProduct | null>(null)
  let [isProductModalOpen, setIsProductModalOpen] = useState(false)

  let _products = useSelector(selectProductList(queryLng))
  let _categoryList = useSelector(selectCategoryList(queryLng))
  let isBrowser = useSelector(selectIsBrowser)

  let { leftMenu } = useMenus(menusSSR)

  let activeCategoryList = useMemo(
    () =>
      isNonEmptyArray(_categoryList)
        ? _categoryList
        : isBrowser
        ? []
        : categoryListSSR,
    [_categoryList, categoryListSSR, isBrowser]
  )

  let productList = useMemo(() => {
    return isPresent(_products) ? _products : productListSSR ?? {}
  }, [_products, productListSSR])

  const openProductModal = useCallback((product: IProduct) => {
    setProduct(product)
    setIsProductModalOpen(true)
  }, [])

  const closeProductModal = useCallback(() => {
    setIsProductModalOpen(false)
    setTimeout(() => {
      setProduct(null)
    }, 500)
  }, [])

  const banners = useSelector(selectBannersList(queryLng))?.banners
  // const isXl = useSelector(selectIsXl)
  const isSm = useSelector(selectIsSm)

  const { items } = useCart()
  const firstCategory = activeCategoryList[0]
  const productsByFirstCategory = useMemo(() => {
    let _products = isPresent(firstCategory)
      ? productList[firstCategory.id]
      : []
    return _products
  }, [firstCategory, productList])

  const renderItem = useCallback(
    (p: IProduct) => {
      const _item = items.find((i) => i.product.id === p.id)
      const isSimpleProduct =
        (p.type === 'simple' || p.variations_count == 1) &&
        isAbsent(p.options?.ingsgroups)

      const quantity = isPresent(_item)
        ? isSimpleProduct
          ? _item.quantity
          : items.reduce((count, i) => {
              if (i.product.id === p.id) count += i.quantity
              return count
            }, 0)
        : 0

      return (
        <ProductCard
          {...p}
          items={items}
          quantity={quantity}
          key={p.id}
          descClassName='font-light'
          cb={() => openProductModal(p)}
          categorySlug={firstCategory?.slug}
        />
      )
    },
    [openProductModal, firstCategory, items]
  )

  useEffect(() => {
    if (isAbsent(window)) return
    const androidAppInstallPrompt = (_: any) => {
      console.log('beforeinstallprompt')
    }
    window.addEventListener(
      'beforeinstallprompt',
      androidAppInstallPrompt
    )
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        androidAppInstallPrompt
      )
    }
  }, [])

  let { data: _seoPage } = useGetPageQuery(
    { lng: queryLng, slug: 'main-seo' },
    { skip: isNonEmptyString(seoPage) }
  )

  return (
    <>
      <Head>
        <meta name='apple-itunes-app' content='app-id=6447418248' />
      </Head>

      <div>
        <Alerts type='page.glavnaya' />

        {isNonEmptyArray(banners) && (
          <HomeBannerSlider banners={banners} />
        )}

        <div className='flex flex-row'>
          <div
            className='relative mt-5 w-full'
            // className='relative mt-5 w-full xl:max-w-[calc(100%-300px)]'
          >
            <HomeCategoryMobileMenu
              categoryList={activeCategoryList}
              leftMenu={leftMenu}
            />

            <div className='flex items-start pt-7 lg:pt-0 pr-0 custom-plx relative w-full'>
              <CategoryLeftMenu
                main={true}
                leftMenu={leftMenu}
                activeCategoryList={activeCategoryList}
              />

              <div className='lg:mb-20 flex-shrink w-full lg:w-[calc(100%-18rem)]'>
                {isNonEmptyArray(activeCategoryList) &&
                  isNonEmptyObj(productList) &&
                  isNonEmptyArray(productsByFirstCategory) && (
                    <>
                      {isSm ? (
                        <div className='grid grid-cols-2 sm:grid-cols-3 mr-5 mb-5'>
                          {productsByFirstCategory.map(
                            (x: IProduct) => renderItem(x)
                          )}
                        </div>
                      ) : (
                        activeCategoryList.map((c: ICategory) => (
                          <CategorySlider
                            wide
                            key={c.id}
                            category={c}
                            products={productList}
                            openProductModal={openProductModal}
                          />
                        ))
                      )}
                      {(isNonEmptyString(seoPage) ||
                        isPresent(_seoPage)) && (
                        <div className='hidden lg:block'>
                          <div
                            itemProp='description'
                            className='text-base2 text-bodyGrey leading-snug font-light my-6'
                            dangerouslySetInnerHTML={{
                              __html: isNonEmptyString(seoPage)
                                ? seoPage
                                : _seoPage!.text,
                            }}
                          ></div>
                        </div>
                      )}
                    </>
                  )}

                {(isEmptyArray(activeCategoryList) ||
                  isEmptyArray(productList)) && (
                  <LoadingOverlay
                    className='h-5 w-5 mx-2.5 bg-accent'
                    open
                  />
                )}
              </div>

              <ProductModal
                isProductModalOpen={isProductModalOpen}
                closeProductModal={closeProductModal}
                product={product}
              />
            </div>

            <div className='hidden lg:block absolute top-8 bottom-0 z-50 right-1 pointer-events-none'>
              <div className='h-full max-h-screen sticky top-2 pb-4 flex flex-col justify-end'>
                <button
                  type='button'
                  className='w-[3.75rem] h-[3.75rem] group rounded-full p-4 bg-primary hover:bg-darkYellow pointer-events-auto'
                  onClick={() => window.scrollTo({ top: 0 })}
                >
                  <ArrowUpIcon className='w-full h-full text-dark' />
                </button>
              </div>
            </div>
          </div>
          {/* {isXl && <PromoPanel />} */}
        </div>
      </div>
    </>
  )
}

export default HomeView
