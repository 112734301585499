/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { ChevronRightIcon } from '@heroicons/react/solid'
import IntLink from 'components/common/IntLink'
import NextButton from 'components/common/NextButton'
import PrevButton from 'components/common/PrevButton'
import ProductCard from 'components/product/ProductCard'
import useCart from 'hooks/useCart'
import { KeenSliderInstance } from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'
import { isAbsent, isEmptyArray, isPresent } from 'lib/utils'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ICategory, IProduct } from 'store/api'
import { selectIsBrowser, selectIsSm } from 'store/ui'

type Props = {
  category: ICategory
  products: Record<string, IProduct[]>
  openProductModal: (p: IProduct) => void
  wide?: boolean
}

const CategorySlider = ({
  category,
  products,
  openProductModal,
  wide = false,
}: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [maxIndex, setMaxIndex] = useState(0)

  const [slicedLength, setSlicedLength] = useState(0)

  const isSm = useSelector(selectIsSm)

  useEffect(() => {
    isSm
      ? setSlicedLength(wide ? 4 : 3)
      : setSlicedLength(wide ? 7 : 6)
  }, [isSm])

  useEffect(() => {
    if (slicedLength === allProducts.length) {
      slider?.current?.update()
    }
  }, [slicedLength])

  const allProducts = useMemo(
    () => products[category.id] ?? [],
    [products, category.id]
  )

  const productsByCategory = useMemo(() => {
    let _products =
      products[category.id]?.slice(0, slicedLength) ?? []
    return _products
  }, [category.id, products, slicedLength])

  let isBrowser = useSelector(selectIsBrowser)

  let [ref, slider] = useKeenSlider<HTMLDivElement>({
    renderMode: 'performance',
    slides: { perView: 1.3 },
    breakpoints: {
      '(min-width: 	640px)': {
        drag: false,
        slides: { perView: 2.2 },
      },
      '(min-width: 	768px)': {
        drag: false,
        slides: { perView: wide ? 4.2 : 3.2 },
      },
      '(min-width: 1536px)': {
        drag: false,
        slides: { perView: wide ? 5.2 : 4.2 },
      },
    },
    created(slider) {
      setMaxIndex(slider.track.details.maxIdx)
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
      setMaxIndex(slider.track.details.maxIdx)
      loadRest(slider)
    },
  })

  const loadRest = useCallback(
    (slider: KeenSliderInstance) => {
      if (slider.slides.length < allProducts.length) {
        setSlicedLength(allProducts.length)
      }
    },
    [allProducts.length]
  )

  const setPrevSlide = useCallback(() => {
    slider.current?.prev()
  }, [slider])

  const setNextSlide = useCallback(() => {
    slider.current?.next()
  }, [slider])

  const { items } = useCart()

  const renderItem = useCallback(
    (p: IProduct) => {
      const _item = items.find((i) => i.product.id === p.id)
      const isSimpleProduct =
        (p.type === 'simple' || p.variations_count == 1) &&
        isAbsent(p.options?.ingsgroups)

      const quantity = isPresent(_item)
        ? isSimpleProduct
          ? _item.quantity
          : items.reduce((count, i) => {
              if (i.product.id === p.id) count += i.quantity
              return count
            }, 0)
        : 0

      return (
        <div className='keen-slider__slide lazy__slide' key={p.id}>
          {isBrowser && (
            <ProductCard
              {...p}
              descClassName='font-light'
              cb={() => openProductModal(p)}
              categorySlug={category.slug}
              items={items}
              quantity={quantity}
            />
          )}
        </div>
      )
    },
    [isBrowser, items, openProductModal, category.slug]
  )

  if (isEmptyArray(productsByCategory)) return null

  return (
    <div className='mb-10'>
      <div className='flex items-center justify-between md:justify-start mb-4 mr-4 lg:mr-0'>
        <div className='text-xs lg:text-base font-bold font-unbounded uppercase leading-5 mr-4'>
          {category.name}
        </div>
        <IntLink
          className='text-primary hover:underline text-2xs lg:text-sm font-bold font-unbounded uppercase flex gap-1 items-center'
          href={`/categories/${category.slug}`}
        >
          Перейти
          <ChevronRightIcon className='w-5 h-5 text-primary' />
        </IntLink>

        <div className='hidden md:flex gap-4 justify-end flex-1 pr-4 xl:pr-12'>
          <PrevButton
            active={currentSlide > 0}
            onClick={setPrevSlide}
          />
          <NextButton
            active={currentSlide < maxIndex}
            onClick={setNextSlide}
          />
        </div>
      </div>

      <div className='keen-slider' ref={ref}>
        {productsByCategory?.map((p) => renderItem(p))}
      </div>

      <div className='md:hidden flex gap-4 justify-center mt-2'>
        <PrevButton
          active={currentSlide > 0}
          onClick={setPrevSlide}
        />
        <NextButton
          active={currentSlide < maxIndex}
          onClick={setNextSlide}
        />
      </div>
    </div>
  )
}

export default CategorySlider
