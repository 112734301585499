import { SVGProps } from "react"

const StarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='13'
      height='19'
      viewBox='0 0 13 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.32399 10.7758L3.00842 15.2502L6.52842 12.4717L9.84221 15.0458L8.58355 10.8742L12.0396 8.16385L7.72311 8.2017L6.33643 3.75L5.08486 8.22441L0.960414 8.26228L4.32399 10.7758Z'
        fill='#D21C22'
      />
    </svg>
  )
}

export default StarIcon
