import { Transition } from '@headlessui/react'
import clsx from 'clsx'
import LoadingDots from './LoadingDots'

type Props = {
  open: boolean
  fixed?: boolean
  wrapperClassName?: string
  className?: string
}

let LoadingOverlay = ({
  open,
  fixed = false,
  wrapperClassName = 'bg-white bg-opacity-80',
  className,
}: Props) => (
  <Transition
    show={open}
    as='div'
    className={clsx(
      wrapperClassName,
      'inset-0 cursor-not-allowed z-60',
      { absolute: !fixed, fixed }
    )}
    enter='transition-opacity ease-in-out duration-75'
    enterFrom='opacity-0'
    enterTo='opacity-100'
    leave='transition-opacity ease-in-out duration-75'
    leaveFrom='opacity-100'
    leaveTo='opacity-0'
  >
    <div className='absolute inset-x-0 bottom-1/2'>
      <LoadingDots className={className} />
    </div>
  </Transition>
)

export default LoadingOverlay
