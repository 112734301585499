import clsx from 'clsx'

let ArrowUpIcon = ({ className }: { className?: string }) => (
  <svg
    className={clsx(className)}
    viewBox='0 0 21 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 26L10 2L10 26ZM10 2L1 11.4286L10 2ZM10 2L19 11.4286L10 2Z'
      fill='currentColor'
    />
    <path
      d='M10 2L19 11.4286M10 26L10 2L10 26ZM10 2L1 11.4286L10 2Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='square'
    />
  </svg>
)

export default ArrowUpIcon
