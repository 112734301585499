import { IPage } from '@types'
import HomeView from 'components/home/HomeView'
import Layout from 'components/layouts/Layout'
import { BASE_URL } from 'config/constants'
import fetcher from 'lib/fetcher'
import { getAllProducts } from 'lib/getAllProducts'
import { getMenus } from 'lib/getMenus'
import { getPage } from 'lib/getPage'
import { setCacheControl } from 'lib/misc'
import { isPresent, isSame } from 'lib/utils'
import {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next'

import { ICategory, IMenuItem, IProduct } from 'store/api'

export let getServerSideProps = async ({
  req,
  res,
}: GetServerSidePropsContext) => {
  // Stop from running on client
  let isFirstServerCall = isSame(
    req?.url?.indexOf('/_next/data/'),
    -1
  )

  const emptyProps = {
    seoPage: '',
    title: '',
    description: '',
    keywords: '',
    categoryListSSR: [] as ICategory[],
    productListSSR: {} as Record<string, IProduct[]>,
    menus: {} as Record<string, IMenuItem[]>,
  }

  if (!isFirstServerCall) return { props: emptyProps }

  let { data: categoryData } = await fetcher<{
    categories: ICategory[]
  }>({
    url: `${BASE_URL}/v1/categories/getall`,
    headers: { lng: 'ru' },
  })

  if (categoryData?.categories.length === 0)
    return { props: emptyProps, notFound: true }

  setCacheControl(res)

  let { products } = await getAllProducts('ru')
  const slicedCategories = categoryData?.categories.slice(0, 3)
  let slicedProducts: Record<string, IProduct[]> = {}

  if (isPresent(slicedCategories))
    for (const c of slicedCategories) {
      slicedProducts = {
        ...slicedProducts,
        [c.id]: products[c.id]?.slice(0, 3),
      }
    }

  let menus = await getMenus('ru')

  let seoPage = await getPage('ru', 'main-seo')

  return {
    props: {
      seoPage: seoPage.text ?? '',
      title: seoPage.meta.title ?? '',
      description: seoPage.meta.description ?? '',
      keywords: seoPage.meta.keywords ?? '',
      categoryListSSR: categoryData?.categories ?? [],
      productListSSR:
        slicedProducts ?? ({} as Record<string, IProduct[]>),
      menus,
    },
  }
}

export type HomePageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
>

let HomePage: IPage<HomePageProps> = (props) => (
  <HomeView {...props} />
)

HomePage.getLayout = (page: { props: HomePageProps }) => (
  <Layout
    title={
      page.props.title ||
      'Доставка канапе и фуршетных блюд - Moscowfood'
    }
    description={
      page.props.description ||
      'Доставка канапе и фуршетных блюд - Moscowfood по Москве'
    }
    keywords={
      page.props.keywords ||
      'канапе тарталетки рулетики доставка кетеринг фуршетные блюда'
    }
    menus={page.props.menus}
    index
    home
  >
    {page}
  </Layout>
)

export default HomePage
